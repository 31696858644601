class AbortSignalFacade implements AbortSignal {
	public readonly aborted = false;
	public readonly reason = '';
	public onabort = () => {};
	public throwIfAborted = () => {};
	public addEventListener = () => {};
	public removeEventListener = () => {};
	public dispatchEvent = () => false;
}

export class AbortControllerFacade implements AbortController {
	public abort() {}
	public signal: AbortSignal = new AbortSignalFacade();

	constructor() {
		if (typeof window === 'object' && window.AbortController) {
			const controller = new AbortController();
			this.abort = controller.abort.bind(controller);
			this.signal = controller.signal;
		}
	}
}
