import { AbortControllerFacade } from '../abort-controller';

import type { CreateServiceRequest, CreateServiceRequestWithAbort } from './types';

export const request: CreateServiceRequest =
	(url, options = {}) =>
	() =>
		fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			...options,
		});

export const requestWithAbort: CreateServiceRequestWithAbort = (url, options = {}) => {
	const controller = new AbortControllerFacade();
	return [request(url, { signal: controller.signal, ...options }), controller];
};

export class HttpError extends Error {
	code: number | undefined;
	traceId?: string | null;
	constructor(code?: number, reason?: string, traceId?: string | null) {
		super(reason);
		this.code = code;
		this.traceId = traceId;
	}
}
