export type LegionErrorResponse = {
	error: string;
	status: number;
	path: string;
	timestamp: string;
};

export interface ILegionError extends Error {
	message: string;
	status: number;
	path: string;
	timestamp: string;
}

export class LegionError extends Error implements ILegionError {
	message: string;
	status: number;
	path: string;
	timestamp: string;

	constructor(data: LegionErrorResponse, ...params: Parameters<ErrorConstructor>) {
		super(...params);

		this.name = 'LegionError';

		this.message = data.error;
		this.status = data.status;
		this.path = data.path;
		this.timestamp = data.timestamp;
	}
}
