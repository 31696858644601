import type { StoreActionApi } from 'react-sweet-state';

import { type LegionError } from '../../utils/legion';
import type { ServiceResult } from '../use-service';

export enum TeamsPermissionApi {
	canCreateTeams = 'CAN_CREATE_TEAMS',
	canViewTeams = 'CAN_VIEW_TEAMS',
	canAdminTeams = 'CAN_ADMIN_TEAMS',
}

export type TeamsPermissionsApi = TeamsPermissionApi[];

export type TeamsPermissionsResponse = {
	permissions: TeamsPermissionsApi;
};

export type TeamsPermissions = {
	canCreateTeams: boolean;
	canViewTeams: boolean;
	canAdminTeams?: boolean;
};

export type TeamsPermissionsServiceResult = ServiceResult<TeamsPermissionsResponse, LegionError> &
	TeamsPermissionsStore;

export type TeamsPermissionsService = (
	scope:
		| {
				orgId?: string;
				cloudId?: string;
		  }
		// backwards compatibility allow orgId to be passed as a string
		| string
		| undefined,
	options?: {
		initialState?: Partial<TeamsPermissionsServiceResult>;
		enabled: boolean;
		attributes?: {
			userId?: string;
		};
	},
) => TeamsPermissionsServiceResult;

export type TeamsPermissionsStore = {
	error?: LegionError;
	hasLoaded: boolean;
	isLoading: boolean;
	permissions: TeamsPermissions;
};

type StoreApi = StoreActionApi<TeamsPermissionsStore>;
type Action = (api: StoreApi) => void;

export type TeamsPermissionsActions = {
	setError: (error: LegionError) => Action;
	setLoading: (isLoading: boolean) => Action;
	setPermissions: (permissions: TeamsPermissionsApi) => Action;
};
