import { useCallback, useEffect, useState } from 'react';

import type { LazyService, Service, ServiceResult } from './types';
import { HttpError } from './utils';

export const useLazyService = <TData, TError extends Error = Error>(
	...[request, controller, options]: Parameters<Service<TData, TError>>
): ReturnType<LazyService<TData, TError>> => {
	const [state, setState] = useState<ServiceResult<TData, TError>>({
		loading: false,
		error: undefined,
		data: undefined,
		...options?.initialState,
	});

	const getData = useCallback(async () => {
		setState((s) => ({
			...s,
			loading: true,
		}));

		try {
			const response = await request();
			const traceId = response?.headers?.get('atl-traceid');
			let jsonResponse: TData | undefined;
			let isResponseText = false;
			const resText = (await response?.text()) || '{}';
			try {
				jsonResponse = JSON.parse(resText);
			} catch {
				isResponseText = true;
			}

			if (!response?.ok || isResponseText) {
				if (options?.ErrorHandler) {
					throw new options.ErrorHandler({
						...jsonResponse,
						traceId,
						status: response?.status,
						statusText: response?.statusText || resText,
					});
				} else {
					throw new HttpError(response?.status, response?.statusText || resText, traceId);
				}
			}

			setState((s) => ({
				...s,
				loading: false,
				data: jsonResponse,
			}));

			return response;
		} catch (error) {
			setState((s) => ({
				...s,
				loading: false,
				error: error as TError,
			}));
		}
	}, [request, options?.ErrorHandler]);

	useEffect(() => {
		return () => {
			controller.abort();
		};
	}, [controller]);

	return [getData, state];
};

export const useService = <TData, TError extends Error = Error>(
	...[request, controller, options]: Parameters<Service<TData, TError>>
): ReturnType<Service<TData, TError>> => {
	const [getData, state] = useLazyService<TData, TError>(request, controller, {
		...options,
		initialState: { ...options?.initialState, loading: true },
	});

	useEffect(() => {
		getData();
	}, [getData]);

	return state;
};
